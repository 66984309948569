<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('PostSettings')"
    v-on:submit="save"
  >
    <template v-slot:content>
      <div class="setting-box">
        <v-radio-group v-model="selected_display" class="mt-0">
          <template v-slot:label>
            <div class="text--primary">{{ $t("SelectListType") }}</div>
          </template>
          <div class="pt-1"></div>
          <v-radio
            :value="item.value"
            class="mt-1"
            v-for="item in display_types"
            :key="'t' + item.value"
          >
            <template v-slot:label>
              <div>
                <div class="global-list-header mb-0">{{ item.title }}</div>
                <div class="global-list-desc">{{ item.description }}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="setting-box">
        <v-radio-group v-model="selected_cover" class="mt-0">
          <template v-slot:label>
            <div class="text--primary">{{ $t("CoverDisplay") }}</div>
          </template>
          <div class="pt-2"></div>
          <v-radio
            :value="item.value"
            class="mt-1"
            v-for="item in cover_types"
            :key="'c' + item.value"
          >
            <template v-slot:label>
              <div>
                <div class="global-list-header mb-0">{{ item.title }}</div>
                <div class="global-list-desc">{{ item.description }}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <v-select
        :items="[10, 15, 20, 25, 30]"
        :label="$t('PageSize')"
        :disabled="is_saving"
        outlined
        v-model="pagesize"
        hide-details
        class="mt-2"
      ></v-select>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "PostSettings": "Post settings",
      "SelectListType": "How to display the posts",
      "ListView": "List-view",
      "CardView": "Card-view",
      "ListDesc": "Listed from top to bottom with title and text.",
      "CardDesc": "Several cards on each row with a title and preamble.",

      "CoverDisplay": "How to display covers on post",
      "CoverTop": "On top",
      "CoverUnderTitle": "Below title",
      "CoverOff": "None.",
      "CoverTopDesc": "Display the cover over the title on the post",
      "CoverUnderTitleDesc": "Display the cover between the title an the text",
      "CoverOffDesc": "Don't display any covers on the posts.",
      "SettingsUpdated": "Settings updated!",
      "PageSize": "Number of posts / page"
    },
    "sv": {
      "PostSettings": "Inställningar för inlägg",
      "SelectListType": "Välj vilken vy du vill visa inläggen i",
      "ListView": "List-vy",
      "CardView": "Kort-vy",
      "ListDesc": "Listas uppifrån och ner med rubrik och helt texten.",
      "CardDesc": "Visas som kort, flera på varje rad med en ingress.",

      "CoverDisplay": "Hur ska inläggens omslagsbilder visas",
      "CoverTop": "Överst",
      "CoverUnderTitle": "Under rubriken",
      "CoverOff": "Visa ej",
      "CoverTopDesc": "Visa bilden över rubriken på inlägget",
      "CoverUnderTitleDesc": "Visa bilden mellan rubriken och texten",
      "CoverOffDesc": "Visa inga omslagsbilder",
      "SettingsUpdated": "Inställningarna uppdaterades!",
      "PageSize": "Antal inlägg / sida"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "post_settings_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {},
  data: () => ({
    is_open: false,
    is_saving: false,

    display_types: [],
    cover_types: [],

    selected_display: 0,
    selected_cover: 1,
    pagesize: 25,
  }),
  created: function () {
    this.display_types = [
      {
        value: 0,
        title: this.$t("ListView"),
        description: this.$t("ListDesc"),
      },
      {
        value: 1,
        title: this.$t("CardView"),
        description: this.$t("CardDesc"),
      },
    ];

    this.cover_types = [
      {
        value: 1,
        title: this.$t("CoverTop"),
        description: this.$t("CoverTopDesc"),
      },
      {
        value: 2,
        title: this.$t("CoverUnderTitle"),
        description: this.$t("CoverUnderTitleDesc"),
      },
      {
        value: 0,
        title: this.$t("CoverOff"),
        description: this.$t("CoverOffDesc"),
      },
    ];
  },
  methods: {
    ...mapActions({
      updateSettings: "diary/updateSettings",
    }),
    open() {
      this.is_saving = false;
      this.is_open = true;

      var data = this.settings[this.$enums.DIARYSETTINGS.BlogPage];

      if (data) {
        this.selected_display = data.list_type;
        this.selected_cover = data.cover_display;
        this.pagesize = data.pagesize;
      }
    },

    save() {
      var self = this;
      self.is_saving = true;

      var data = {
        cover_display: self.selected_cover,
        list_type: self.selected_display,
        pagesize: self.pagesize,
      };

      self
        .updateSettings({
          name: self.$enums.DIARYSETTINGS.BlogPage,
          data: data,
        })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.$emit("updated", data);
          self.$successNoty(self.$t("SettingsUpdated"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
  },
};
</script>